<template>
  <div>
    <v-progress-circular
      v-if="loading"
      indeterminate
    ></v-progress-circular>
    <v-data-table
        :headers="headers"
        :items="inverters"
        :loading="grid_loading"
        loading-text="Loading... Please wait"
        hide-default-footer
        class="compact hover"
      >
      <template v-slot:item.missing_count="{ item }">
        <div v-if="item.missing_dates.length > 0">
          {{ item.missing_dates.length }}
        </div>
        <div v-else>
            0
        </div>
      </template>
      <template v-slot:item.missing_dates="{ item }">
        <v-tooltip v-if="item.missing_dates.length > 0" bottom>
        <template v-slot:activator="{ on }">
            <v-icon color="error" v-on="on">
                mdi-alert
            </v-icon>
        </template>
        <span>
            <li v-for="item in item.missing_dates" :key="item">
            {{ item }}
            </li>
        </span>
        </v-tooltip>
        <v-tooltip v-else bottom>
        <template v-slot:activator="{ on }">
            0
        </template>
        <span>
            <li v-for="item in item.missing_dates" :key="item">
            {{ item }}
            </li>
        </span>
        </v-tooltip>
      </template>
    </v-data-table>  
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import Vue from 'vue';

export default {
  props: ["site", "date"],
  data() {
    return {
      loading: false,
      grid_loading: false,
      headers: [
        {
          text: "Inverter ID",
          value: "inverter_id",
        },
        {
          text: 'Name',
          value: 'inverter_name',
        },
        {
          text: 'Missing Count',
          value: 'missing_count',
        },
        {
          text: 'Missing Dates',
          value: 'missing_dates',
        },
      ],
      inverters: [],
      expandedInverterDetails: [],
      statusMap: null,
      isOnline: null,
    };
  },
  created() {
    this.loadMissingInverterStats(this.site.id, this.date)
  },
  beforeMount() {
    var date = new Date();
    this.toDateVal = this.$moment(date).format("YYYY-MM-DD");
    // this.toDateVal = today;
  },
  methods: {
    loadMissingInverterStats(site_id, date) {
      this.grid_loading = true;
      Service.loadMissingInverterStats(site_id, date)
        .then(response => {
          this.inverters = response.data.data;
          this.grid_loading = false;
        })
        .catch(error => {
          console.log('Error getting data:', error.response);
          this.grid_loading = false;
        });
    },
    getInverterDescription(inverterMetrics, index) {
      return inverterMetrics.description || `inverter ${index + 1}`;
    },
  },
  computed: {
    
  },
};
</script>

<style>

</style>