var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
        : _vm._e(),
      _c("v-data-table", {
        staticClass: "compact hover",
        attrs: {
          headers: _vm.headers,
          items: _vm.inverters,
          loading: _vm.grid_loading,
          "loading-text": "Loading... Please wait",
          "hide-default-footer": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item.missing_count",
            fn: function (ref) {
              var item = ref.item
              return [
                item.missing_dates.length > 0
                  ? _c("div", [
                      _vm._v(" " + _vm._s(item.missing_dates.length) + " "),
                    ])
                  : _c("div", [_vm._v(" 0 ")]),
              ]
            },
          },
          {
            key: "item.missing_dates",
            fn: function (ref) {
              var item = ref.item
              return [
                item.missing_dates.length > 0
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g({ attrs: { color: "error" } }, on),
                                    [_vm._v(" mdi-alert ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "span",
                          _vm._l(item.missing_dates, function (item) {
                            return _c("li", { key: item }, [
                              _vm._v(" " + _vm._s(item) + " "),
                            ])
                          }),
                          0
                        ),
                      ]
                    )
                  : _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [_vm._v(" 0 ")]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "span",
                          _vm._l(item.missing_dates, function (item) {
                            return _c("li", { key: item }, [
                              _vm._v(" " + _vm._s(item) + " "),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }