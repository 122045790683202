<template>
  <div>
    <v-form ref="form">
      <br />
      <div>
        <h5>Select missing kwh date</h5>
        <div class="small">
          These metrics will show how many dates are missing (no data collected) for each site and the given date.
          You can also expand the site to view the metrics for the inverters for that site.
        </div>
      </div>
      <v-row>
        <!-- date -->
        <v-col cols="6" md="6">
          <v-menu
            v-model="fromDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                label="From"
                prepend-icon="event"
                readonly
                :value="fromDateDisp"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="en-in"
              v-model="fromDateVal"
              no-title
              @input="fromDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-btn 
        :ripple="false"
        :elevation="0"
        class="font-weight-bold text-xs btn-default bg-gradient-default" 
        @click="showMissing(fromDateVal)"
        id="kwh_missing"
      >
        Show Missing
      </v-btn>
      <!-- <v-btn color="success" class="mr-4" @click="reloadAll()">
        Reload All</v-btn
      > -->
      <br />
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :headers="headers"
            :items="kwh_data"
            :items-per-page="5"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="id"
            show-expand
            class="elevation-1"
          >
            <template v-slot:item.nativePlatformLinkIconPlaceholder="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <img
                    :src="
                      require('@/assets/img/' + item.monitoring_system + '.png')
                    "
                    v-if="item.monitoring_url"
                    v-on="on"
                    v-on:click.stop.prevent="openWindow(item.monitoring_url)"
                  />
                </template>
                <span>Monitoring Link</span>
              </v-tooltip>
              <template>
                <v-icon v-if="!item.monitoring_url" disabled
                  >mdi-web-box</v-icon
                >
              </template>
            </template>
            <!-- add note icon -->
            <template v-slot:item.addNoteIconPlaceholder="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon @click="openNotes(item)" v-on="on">mdi-note-plus</v-icon>
                </template>
                <span>View Notes</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td colspan="100%">
                <inverter-missing-kwh v-bind:site="item" v-bind:date="fromDateVal">
                </inverter-missing-kwh>
              </td>
            </template>      
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
    <!-- new notes -->
    <v-dialog v-model="showNotesModal" width="50%">
      <v-card>
        <v-card-title> Site Notes </v-card-title>
        <v-card-text>
          <template>
            <div class="container">
              <div class="row mb-3">
                <template>
                  <form class="col-12" @submit.prevent="addNote()">
                    <v-text-field
                      label="Create a new note..."
                      v-model="newNote"
                    ></v-text-field>
                  </form>
                </template>
              </div>
              <div class="row">
                <div class="col-12">
                  <ul class="list-group" style="padding-left: 0px">
                    <note
                      v-for="note in notes"
                      :description="note.note"
                      :date="note.date"
                      :id="note.id"
                      @on-toggle="toggleNote(note)"
                      @on-delete="deleteNote(note)"
                      @on-edit="editNote(note, $event)"
                    />
                  </ul>
                </div>
              </div>
            </div>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :ripple="false"
            :elevation="0"
            class="
              font-weight-bold
              text-xs
              btn-default
              bg-gradient-danger
              shadow-danger
            "
            @click="closeNotes()"
            >Close Notes</v-btn
          >
          <!-- <v-btn color="primary"
              @click="saveNotes()"
               >
          Save
        </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Service from '@/services/Service.js';
import Note from "../../components/Note";
import InverterMissingKwh from '../inverter/InverterMissingKwh.vue';
export default {
  components: { 
    Note,
    InverterMissingKwh
  },
  data() {
    return {
      monitorSystem: null,
      showNotesModal: false,
      newNote: "",
      notes: [],
      props: null,
      toDateMenu: null,
      fromDateMenu: null,
      fromDateVal: null,
      toDateVal: null,
      stats: [],
      expanded: [],
      singleExpand: false,
      items: ['Solaredge', 'Fronius', 'Solarlog', 'Locus', 'Also', 'Enphase', 'Powerdash', 'GMP', "Allearth", "Auroravision", "Chint", "Egauge", "Pika", "Smasunnyportal", "Solectria"],
      headers: [
        { text: 'Site ID', value: 'id' },
        { text: 'Name', value: 'name'},
        {
          text: "Missing Days (past month)",
          value: "missing_dates",
        },
        {
          text: "Monitoring Link",
          value: "nativePlatformLinkIconPlaceholder",
        },
        {
          text: "Notes",
          value: "addNoteIconPlaceholder",
        },
      ],
      kwh_data: [],
    };
  },
  beforeMount(){
    var date = new Date();
    this.toDateVal = this.$moment(date).format('YYYY-MM-DD');
    // this.toDateVal = today;
    console.log(`${this.$route.params.date}`)
    if(this.$route.params.date) {
      this.fromDateVal = this.$route.params.date;
      this.showMissing(this.$route.params.date)
    }
    var title = 'Missing KwH'
    document.title = title
  },
  methods: {
      compareTime(time1, time2) {
        return new Date(time1) >= new Date(time2); // true if time1 is later
      },
      showMissing(startDate) {
        NProgress.start();
        Service.getMissingKwh(startDate)
          .then((response) => {
              NProgress.done();
              this.kwh_data = response.data.missing_kwh;
          })
          .catch((error) => {
            NProgress.done();
            Vue.$toast.error(`Error reloading data`, {position: "top-right"})
            console.error(error)
          })
      },
      openWindow: function(link) {
        window.open(link, "_blank");
      },
      openNotes(item) {
        this.showNotesModal = true;
        this.editSiteId = item.id;
        Service.getNotes(item.id)
          .then((response) => {
            this.notes = response.data;
          })
          .catch((error) => {
            console.log("Error getting sites data:", error.response);
          });
      },
      closeNotes() {
        this.showNotesModal = false;
        this.editSiteId = null;
      },      
      addNote() {
        var params = {
          site_id: this.editSiteId,
          note: this.newNote,
        };
        Service.createNote(this.editSiteId, params)
          .then((response) => {
            return Service.getNotes(this.editSiteId);
          })
          .then((response) => {
            this.notes = response.data;
          })
          .catch((error) => {
            console.log("Error creating site:", error.response);
          });
        this.newNote = "";
      },
      deleteNote(deletedNote) {
        Service.deleteNote(this.editSiteId, deletedNote.id)
          .then((response) => {
            return Service.getNotes(this.editSiteId);
          })
          .then((response) => {
            this.notes = response.data;
          })
          .catch((error) => {
            console.log("Error creating site:", error.response);
          });
      },
      editNote(note, newNoteDescription) {
        // note.description = newNoteDescription;
        var params = {
          site_id: this.editSiteId,
          id: note.id,
          note: newNoteDescription,
        };
        Service.updateNote(this.editSiteId, note.id, params)
          .then((response) => {
            return Service.getNotes(this.editSiteId);
          })
          .then((response) => {
            this.notes = response.data;
          })
          .catch((error) => {
            console.log("Error updating note:", error.response);
          });
      },    
  },
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },
    toDateDisp() {
      return this.toDateVal;
    }
  }
};
</script>

<style>
#kwh_missing {
  margin-left: 0.5%;
  margin-bottom: 0.5%;
}
</style>