var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c("br"),
          _c("div", [
            _c("h5", [_vm._v("Select missing kwh date")]),
            _c("div", { staticClass: "small" }, [
              _vm._v(
                " These metrics will show how many dates are missing (no data collected) for each site and the given date. You can also expand the site to view the metrics for the inverters for that site. "
              ),
            ]),
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6", md: "6" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  {
                                    attrs: {
                                      label: "From",
                                      "prepend-icon": "event",
                                      readonly: "",
                                      value: _vm.fromDateDisp,
                                    },
                                  },
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.fromDateMenu,
                        callback: function ($$v) {
                          _vm.fromDateMenu = $$v
                        },
                        expression: "fromDateMenu",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: { locale: "en-in", "no-title": "" },
                        on: {
                          input: function ($event) {
                            _vm.fromDateMenu = false
                          },
                        },
                        model: {
                          value: _vm.fromDateVal,
                          callback: function ($$v) {
                            _vm.fromDateVal = $$v
                          },
                          expression: "fromDateVal",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass:
                "font-weight-bold text-xs btn-default bg-gradient-default",
              attrs: { ripple: false, elevation: 0, id: "kwh_missing" },
              on: {
                click: function ($event) {
                  return _vm.showMissing(_vm.fromDateVal)
                },
              },
            },
            [_vm._v(" Show Missing ")]
          ),
          _c("br"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.kwh_data,
                      "items-per-page": 5,
                      "single-expand": _vm.singleExpand,
                      expanded: _vm.expanded,
                      "item-key": "id",
                      "show-expand": "",
                    },
                    on: {
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.nativePlatformLinkIconPlaceholder",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          item.monitoring_url
                                            ? _c(
                                                "img",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      src: require("@/assets/img/" +
                                                        item.monitoring_system +
                                                        ".png"),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.openWindow(
                                                          item.monitoring_url
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                )
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Monitoring Link")])]
                            ),
                            [
                              !item.monitoring_url
                                ? _c("v-icon", { attrs: { disabled: "" } }, [
                                    _vm._v("mdi-web-box"),
                                  ])
                                : _vm._e(),
                            ],
                          ]
                        },
                      },
                      {
                        key: "item.addNoteIconPlaceholder",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openNotes(item)
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [_vm._v("mdi-note-plus")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("View Notes")])]
                            ),
                          ]
                        },
                      },
                      {
                        key: "expanded-item",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _c(
                              "td",
                              { attrs: { colspan: "100%" } },
                              [
                                _c("inverter-missing-kwh", {
                                  attrs: { site: item, date: _vm.fromDateVal },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "50%" },
          model: {
            value: _vm.showNotesModal,
            callback: function ($$v) {
              _vm.showNotesModal = $$v
            },
            expression: "showNotesModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Site Notes ")]),
              _c(
                "v-card-text",
                [
                  [
                    _c("div", { staticClass: "container" }, [
                      _c(
                        "div",
                        { staticClass: "row mb-3" },
                        [
                          [
                            _c(
                              "form",
                              {
                                staticClass: "col-12",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addNote()
                                  },
                                },
                              },
                              [
                                _c("v-text-field", {
                                  attrs: { label: "Create a new note..." },
                                  model: {
                                    value: _vm.newNote,
                                    callback: function ($$v) {
                                      _vm.newNote = $$v
                                    },
                                    expression: "newNote",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "ul",
                            {
                              staticClass: "list-group",
                              staticStyle: { "padding-left": "0px" },
                            },
                            _vm._l(_vm.notes, function (note) {
                              return _c("note", {
                                attrs: {
                                  description: note.note,
                                  date: note.date,
                                  id: note.id,
                                },
                                on: {
                                  "on-toggle": function ($event) {
                                    return _vm.toggleNote(note)
                                  },
                                  "on-delete": function ($event) {
                                    return _vm.deleteNote(note)
                                  },
                                  "on-edit": function ($event) {
                                    return _vm.editNote(note, $event)
                                  },
                                },
                              })
                            }),
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "\n            font-weight-bold\n            text-xs\n            btn-default\n            bg-gradient-danger\n            shadow-danger\n          ",
                      attrs: { ripple: false, elevation: 0 },
                      on: {
                        click: function ($event) {
                          return _vm.closeNotes()
                        },
                      },
                    },
                    [_vm._v("Close Notes")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }